
    import Vue from 'vue';
    import { Component } from 'vue-property-decorator';
    import { Invitee, createResponse } from '@/api/Response';

    import MessageBox from '@/components/MessageBox.vue';

    interface FormInvitee {
        firstname: string | null,
        lastname: string | null,
        status: string | null,
        type: string | null,
        menu: string | null,
        comments: string | null,
    }

    @Component({

      name: 'Home',
      components: {
        MessageBox
      }
    })
    export default class Home extends Vue  {

        // Member:
        public section: string | null = null;
        public scrollTop = 0;
        public drawer = false;

        public onScroll(e: Event) {
            if (e.target) {
                if (e.target instanceof Document) {
                    if (e.target.scrollingElement) {
                        this.scrollTop = e.target.scrollingElement?.scrollTop;
                    }
                }
            }
        }

        public sending = false;
        public error: any | null = null;
        public errorCode: number | null = null;
        public invitees: FormInvitee[] = [];
        public showResult = false;
        public responseValid = false;

        public personTypes = [
            { value: 'Adult', text: 'response.typeAdult'},
            { value: 'Child', text: 'response.typeChild'},
            { value: 'Baby', text: 'response.typeBaby'}
        ];

        public mealTypes = [
            { value: 'Meat', text: 'response.eatsMeat'},
            { value: 'Vegetarian', text: 'response.eatsVegetarian'},
            { value: 'Nothing', text: 'response.eatsNothing'}
        ];

        public agenda = [
            { header: 'agenda.header1', content: 'agenda.content1' },
            { header: 'agenda.header2', content: 'agenda.content2' },
            { header: 'agenda.header3', content: 'agenda.content3' },
            { header: 'agenda.header4', content: 'agenda.content4' },
            { header: 'agenda.header5', content: 'agenda.content5' },
            { header: 'agenda.header6', content: 'agenda.content6' },
            { header: 'agenda.header7', content: 'agenda.content7' },
            { header: 'agenda.header8', content: 'agenda.content8' },
        ];

        public hotels = [
            { header: 'hotels.header1', content: 'hotels.content1', map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.0807908215065!2d11.420438076846013!3d47.896121567939474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dc69630296b17%3A0xef960eac891a8d04!2sServus%20Gelting!5e0!3m2!1sde!2sde!4v1683278402806!5m2!1sde!2sde" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' },
            { header: 'hotels.header2', content: 'hotels.content2', map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.2325057769613!2d11.420811776845778!3d47.89318496814308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dc6be108dd5bf%3A0xf241bc8f2dfe0434!2sHotel%20Alter%20Wirth!5e0!3m2!1sde!2sde!4v1683278427196!5m2!1sde!2sde" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' },
            { header: 'hotels.header3', content: 'hotels.content3', map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.2173345970195!2d11.419447376846664!3d47.91283206678032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dc51ab75b9799%3A0x16cf9ccb29efc2d1!2sLandhaus%20Caf%C3%A9%20Restaurant!5e0!3m2!1sde!2sde!4v1683278457295!5m2!1sde!2sde" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' },
            { header: 'hotels.header4', content: 'hotels.content4', map: '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10716.906427367974!2d11.4810329!3d47.815826!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dbf8583aa397f%3A0x2f6232709cca457e!2sPosthotel%20Hofherr!5e0!3m2!1sde!2sde!4v1683755598322!5m2!1sde!2sde" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' },
        ];

        public faqs = [
            { question: 'faq.question1', content: 'faq.answercontent1' },
            { question: 'faq.question2', content: 'faq.answercontent2' },
            { question: 'faq.question3', content: 'faq.answercontent3' },
            { question: 'faq.question4', content: 'faq.answercontent4' },
            { question: 'faq.question5', content: 'faq.answercontent5' },
            { question: 'faq.question6', content: 'faq.answercontent6' },
            { question: 'faq.question7', content: 'faq.answercontent7' },
            { question: 'faq.question8', content: 'faq.answercontent8' },
            { question: 'faq.question9', content: 'faq.answercontent9' },
            { question: 'faq.question10', content: 'faq.answercontent10' },
            { question: 'faq.question11', content: 'faq.answercontent11' },
            { question: 'faq.question12', content: 'faq.answercontent12' },
            { question: 'faq.question13', content: 'faq.answercontent13' },
            { question: 'faq.question14', content: 'faq.answercontent14' },
        ];


        // Component Lifecycle Methods:
        public mounted() {
            this.initResponse();
        }

        // Methods
        public switchLocale(locale: string): void {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                this.$vuetify.lang.current = locale;
            }
        }

        handleStart(entries: any, observer: any) {
            if(entries[0].isIntersecting) {
                this.section = null;
            }
        }
        handleAgenda(entries: any, observer: any) {
            if(entries[0].isIntersecting) {
                this.section = "/agenda";
            }
        }
        handleResponse(entries: any, observer: any) {
            if(entries[0].isIntersecting) {
                this.section = "/response";
            }
        }
        handleLocations(entries: any, observer: any) {
            if(entries[0].isIntersecting) {
                this.section = "/locations";
            }
        }
        handleHotels(entries: any, observer: any) {
            if(entries[0].isIntersecting) {
                this.section = "/hotels";
            }
        }
        handleFaq(entries: any, observer: any) {
            if(entries[0].isIntersecting) {
                this.section = "/faq";
            }
        }

        public typeChanged(invitee: FormInvitee, value: string) {
            if(invitee && value === 'Baby') {
                invitee.menu = 'Nothing';
            }  
        }

        public initResponse() {
            this.invitees = [{
                firstname: null,
                lastname: null,
                status: 'Accepted',
                type: null,
                menu: null,
                comments: null,
            }];

            (this.$refs.responseForm as HTMLFormElement)?.reset();
        }

        public addInvitee() {
            this.invitees.push({
                firstname: null,
                lastname: null,
                status: 'Accepted',
                type: null,
                menu: null,
                comments: null,
            });
        }

        public deleteInvitee(invitee: FormInvitee) {
            const index = this.invitees.indexOf(invitee);

            if(index >= 0) {
                this.invitees.splice(index, 1);
            }
        }

        public async sendResponse() {
            if (!((this.$refs.responseForm as Vue & { validate: () => boolean }).validate())) {
                return;
            }

            if(this.invitees.length === 0) {
                return;
            }

            const invitees: Invitee[] = [];

            for(const invitee of this.invitees) {
                if(!invitee.firstname) {
                    return;
                }
                if(!invitee.lastname) {
                    return;
                }
                if(!invitee.status) {
                    return;
                }

                invitees.push({
                    firstname: invitee.firstname,
                    lastname: invitee.lastname,
                    status: invitee.status,
                    type: invitee.type ?? undefined,
                    menu: invitee.menu ?? undefined,
                    comments: invitee.comments ?? undefined
                });
            }

            try {
                this.sending = true;

                const response = await createResponse(invitees);

                if(!response.ok) {
                    this.error = null;
                    this.errorCode = response.status;
                }
                else {
                    this.error = null;
                    this.errorCode = null;
                    this.initResponse();
                }
            }
            catch(error: any) {
                this.error = error;
                this.errorCode = null;
            }
            finally {
                this.sending = false;
                this.showResult = true;
            }
        }
    }
