import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import { PositionResult } from 'vue-router/types/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/export',
    name: 'Export',
    component: () => import('../views/Export.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if(to.hash) {
      return { behavior: 'smooth', selector: to.hash} as PositionResult;
    }
    else if(savedPosition) {
      return { y: savedPosition.y } as PositionResult;
    }
    else {
      return { y: 0 } as PositionResult;
    }
  },
})

export default router
