import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#121212',
                secondary: '#424242',
                accent: '#1a237e',
                error: '#b71c1c',
                info: '#fbc02d',
                success: '#1b5e20',
                warning: '#e65100',
                bgwhite: '#ffffff',
                bglight: '#f5f5f5',
                bgdark: '#333333',
                bglightdark: '#BDBDBD'
            }
        }
    }
});
