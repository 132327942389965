const apiBaseUrl = "https://api.vonposchinger.de";

export interface Invitee {
    firstname: string,
    lastname: string,
    status: string,
    type?: string,
    menu?: string,
    comments?: string,
}

export async function createResponse(invitees: Invitee[]): Promise<Response> {
    return await fetch(`${apiBaseUrl}/responses`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(invitees)
    });
}