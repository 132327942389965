import _Vue from "vue";
import i18n from '@/plugins/i18n';

export class Validation {
    public required(value: any, message: string): true | string {
        return !!value || message || i18n.t('validation.required').toString();
    }

    public confirmed(value: boolean, message: string): true | string {
        return value === true || message || i18n.t('validation.confirm').toString();
    }

    public mail(value: string | null | undefined, message: string): true | string {
        return !value || /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i.test(value) || message || i18n.t('validation.mail').toString();
    }
}

export const validation = new Validation();

export function ValidationPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$validation = validation;
}

declare module 'vue/types/vue' {
    interface Vue {
        $validation: Validation;
    }
}

