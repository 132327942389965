
    import Vue from 'vue'
    import { Component, Prop } from 'vue-property-decorator';

    @Component({
        components: {
        },
        model: {
            prop: "model",
            event: "visibilityChanged"
        }
    })
    export default class MessageBox extends Vue {

        @Prop({ required: false, default: false, type: Boolean })
        public model!: boolean;

        @Prop({ required: false })
        public title?: string;

        @Prop({ required: false })
        public text?: string;

        @Prop({ required: false })
        public icon?: string;

        public get visible(): boolean {
            return this.model;
        }
        public set visible(value: boolean) {
            this.fireVisibilityChanged(value);
        }

        public confirm(): void {
            this.visible = false;
        }

        public fireVisibilityChanged(value: boolean): void {
            this.$emit('visibilityChanged', value);
        }

    }
